export default function getCategoriesBlog(categories) {
  let arrCategorias = [];
  if (categories) {
    categories &&
      categories.forEach((categoria) => {
        arrCategorias.push(categoria.name);
      });
    return (arrCategorias = arrCategorias.join(', '));
  }
  return '';
}
