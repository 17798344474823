import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import Icon from '../../atoms/Icon/Icon';

export default function PaginationBlog({ totalPages, pageNumber, eventClick }) {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  const paginationNumbersListCss = css`
    display: flex;
    flex-wrap: wrap;
  `;

  const paginacionCss = css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: calc(100vw * (calc(20 / var(--width_base))));
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(10 / var(--width_base))));
      padding-left: calc(100vw * (calc(20 / var(--width_base))));
      padding-right: calc(100vw * (calc(20 / var(--width_base))));
    }
  `;

  const paginationLinkCss = css`
    padding-right: calc(100vw * (calc(9 / var(--width_base))));
    padding-left: calc(100vw * (calc(9 / var(--width_base))));
    margin-left: calc(100vw * (calc(2 / var(--width_base))));
    margin-right: calc(100vw * (calc(2 / var(--width_base))));
    font-size: calc(100vw * (calc(12 / var(--width_base))));
    color: white;
    height: calc(100vw * (calc(35 / var(--width_base))));
    display: inline-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: calc(100vw * (calc(30 / var(--width_base))));
    margin-bottom: calc(100vw * (calc(5 / var(--width_base))));
    @media (max-width: 767px) {
      margin-bottom: calc(100vw * (calc(3 / var(--width_base))));
    }
  `;

  const paginationLinkNumberCss = css`
    ${paginationLinkCss};
    background-color: #93ba1f;
  `;

  const paginationLinkControlCss = css`
    ${paginationLinkCss};
    background-color: #93ba1f;
  `;

  return (
    <div css={paginacionCss}>
      {pageNumber > 1 && (
        <>
          {pageNumber !== 1 && (
            <button
              css={paginationLinkControlCss}
              onClick={() => eventClick(pageNumber - 1)}
            >
              <Icon type="arrow-left" /> Página anterior
            </button>
          )}
        </>
      )}
      <nav role="navigation" aria-label="Pagination Navigation">
        <ul css={paginationNumbersListCss}>
          {pages &&
            pages.map((page, i) => (
              <li key={i}>
                <button
                  css={paginationLinkNumberCss}
                  onClick={() => eventClick(page)}
                >
                  {page}
                </button>
              </li>
            ))}
        </ul>
      </nav>
      {totalPages !== pageNumber && (
        <button
          css={paginationLinkControlCss}
          onClick={() => eventClick(pageNumber + 1)}
        >
          Página siguiente <Icon type="arrow-right" />
        </button>
      )}
    </div>
  );
}
