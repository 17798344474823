import React, { useState } from 'react';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import Seo from '../components/atoms/Seo/Seo';
import { graphql } from 'gatsby';
import BannerOthersPages from '../components/organisms/OthersPages/BannerOthersPages';
import SearchGridBlog from '../components/template/Blog/SearchGridBlog';

export default function ArchivePost({ data }) {
  const page = data?.wordpress?.page?.template;
  let titlePage = 'Blog';
  let slug_how = data?.wordpress?.page?.uri;

  return (
    <LayoutGeneral title={data?.wordpress?.page?.title} slug={slug_how}>
      <Seo title={titlePage} />
      {page?.blog.imagenPortada && (
        <BannerOthersPages
          title={page?.blog.textoPortada}
          imagen={page?.blog.imagenPortada}
          imagenMovil={page?.blog.imagenMovilPortada}
          typeStyle="banner-page"
          titlePage={page?.title}
        />
      )}
      <SearchGridBlog />
    </LayoutGeneral>
  );
}

export const pageQuery = graphql`
  query GET_POSTS {
    wordpress {
      page(id: "1453", idType: DATABASE_ID) {
        template {
          ... on Wordpress_Template_Blog {
            templateName
            blog {
              textoPortada
              imagenPortada {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
            }
          }
        }
        title
        uri
      }
    }
  }
`;
