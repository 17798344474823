// const token_url = `https://testwebapi.evolta.pe/oauth2/token`;
// const loginUrl = `https://testwebapi.evolta.pe/api/modeloinmueble?proyecto=`;
//
// const user = 'testFundWA';
// const pass = 'dsd52$$%23DFDF7/#';
// const grant = 'password';

const token_url = `https://webapi.evolta.pe/oauth2/token`;
// const loginUrl = `https://webapi.evolta.pe/api/modeloinmueble?proyecto=`
const loginUrl = `https://webapi.evolta.pe/api/inmcotizador/`;

const user = 'EvFundamentaWA';
const pass = 'fUnd4Me66t4#425rt6gFh5';
const grant = 'password';

export const AuthenticationEvolta = async () => {
  if (typeof window === 'undefined') return Promise.resolve(false);

  const token =
    localStorage.getItem('access-token') !== null
      ? JSON.parse(localStorage.getItem('access-token'))
      : null;

  if (token !== null && token.expirationDate > Math.floor(Date.now() / 1000)) {
    return Promise.resolve(token);
  } else {
    const response = await fetch(token_url, {
      method: 'POST',
      body: 'username=' + user + '&grant_type=' + grant + '&password=' + pass,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.ok) {
      const result = await response.json();
      const token = await saveToken(result);
      return Promise.resolve(token);
    }
  }

  if (token === null) {
    const response = await fetch(token_url, {
      method: 'POST',
      body: 'username=' + user + '&grant_type=' + grant + '&password=' + pass,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.ok) {
      const result = await response.json();
      const token = await saveToken(result);
      return Promise.resolve(token);
    }
  }
};

export const saveToken = (json) => {
  const token = { ...json };
  token.date = Math.floor(Date.now() / 1000);
  token.expirationDate = token.date + token.expires_in;
  localStorage.setItem('access-token', JSON.stringify(token));
  return token;
};

export const drupalLogout = async (id) => {
  const oauthToken = await AuthenticationEvolta();
  const logoutoken = oauthToken.access_token;
  if (logoutoken) {
    const res = await fetch(`${loginUrl}${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${logoutoken}`,
      },
    });
    if (res.ok) {
      const data = await res.json();
      return Promise.resolve(data);
    }
  }
};
