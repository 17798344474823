import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { css } from '@emotion/react';
import Button from '../../atoms/Button/Button';

export default function SearchBlog({ eventBtn, eventSelect, categoria }) {
  const data = useStaticQuery(graphql`
    {
      wordpress {
        categories(where: { exclude: "1" }) {
          nodes {
            name
          }
        }
      }
    }
  `);

  const categorias = data?.wordpress?.categories.nodes;

  const contentCss = css`
    max-width: calc(100vw * (calc(835 / var(--width_base))));
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding-top: calc(100vw * (calc(25 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(25 / var(--width_base))));
    }
  `;

  const searchCss = css`
    background-color: #4a4a49;
  `;

  const controlCss = css`
    height: calc(100vw * (calc(28 / var(--width_base))));
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      @media (max-width: 767px) {
        margin-bottom: calc(100vw * (calc(7 / var(--width_base))));
      }
    }
  `;

  const selectCss = css`
    border-radius: 0;
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    line-height: calc(100vw * (calc(11 / var(--width_base))));
    margin-right: calc(100vw * (calc(9 / var(--width_base))));
    border: calc(100vw * (calc(1 / var(--width_base)))) solid #b1b1b1;
    padding-left: calc(100vw * (calc(14 / var(--width_base))));
    padding-right: calc(100vw * (calc(14 / var(--width_base))));
    font-family: 'Montserrat Medium', sans-serif;
    @media (max-width: 767px) {
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      background-color: #c1bfbf;
    }
    ${controlCss};
  `;

  const btnCss = css`
    ${controlCss};
    background-color: #93ba1f;
    color: white;
    width: calc(100vw * (calc(198 / var(--width_base))));
    font-size: calc(100vw * (calc(11 / var(--width_base))));
    line-height: calc(100vw * (calc(11 / var(--width_base))));
    flex-shrink: 0;
    justify-content: center;
    @media (max-width: 767px) {
      margin-top: calc(100vw * (calc(25 / var(--width_base))));
      font-size: calc(100vw * (calc(14 / var(--width_base))));
      line-height: calc(100vw * (calc(14 / var(--width_base))));
      padding-top: calc(100vw * (calc(18 / var(--width_base))));
      padding-bottom: calc(100vw * (calc(18 / var(--width_base))));
    }
  `;

  return (
    <div css={searchCss}>
      <div css={contentCss}>
        <select
          name="categoria"
          css={selectCss}
          onChange={eventSelect}
          value={categoria}
        >
          <option value="all">CATEGORIA</option>
          {categorias &&
            categorias.map((categoria, i) => (
              <option value={categoria.slug}>
                {categoria.name.toUpperCase()}
              </option>
            ))}
        </select>
        <Button typeEle="button" styleEle={btnCss} eventEle={eventBtn}>
          BUSCAR CATEGORIA
        </Button>
      </div>
    </div>
  );
}

SearchBlog.defaultProps = {
  valueCategoria: '',
};
