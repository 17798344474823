import React from 'react';
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import PaginationBlog from '../../organisms/Pagination/PaginationBlog';
import PostEntryBlog from '../../organisms/PostEntry/PostEntryBlog';
import SearchBlog from '../../moleculas/Search/SearchBlog';

export default function SearchGridBlog() {
  const [posts, setPosts] = useState([]);
  const [categoria, setCategoria] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 12; // Debe coincidir con el backend.

  const apiUrl = 'https://apros-qa.net.pe/fundamenta/wp-json/custom/v1';

  // Función para obtener posts
  const fetchPosts = async (page = 1, category = 'all') => {
    try {
      const categoryFilter = category !== 'all' ? `&category=${category}` : '';
      const response = await fetch(
        `${apiUrl}/posts?page=${page}&per_page=${postsPerPage}${categoryFilter}`
      );
      const data = await response.json();

      setPosts(data.posts);
      setTotalPages(data.total_pages);
      setCurrentPage(data.current_page);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  useEffect(() => {
    fetchPosts(currentPage, selectedCategory);
  }, [currentPage, selectedCategory]);

  const linkPagination = (index) => {
    setCurrentPage(index);
  };

  const sectionCss = css`
    background-color: #5e5e5e;
    padding-top: calc(100vw * (calc(45 / var(--width_base))));
    padding-right: calc(100vw * (calc(103 / var(--width_base))));
    padding-left: calc(100vw * (calc(123 / var(--width_base))));
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  `;

  const section2Css = css`
    background-color: #5e5e5e;
    padding-right: calc(100vw * (calc(103 / var(--width_base))));
    padding-bottom: calc(100vw * (calc(45 / var(--width_base))));
    padding-left: calc(100vw * (calc(123 / var(--width_base))));
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  `;

  const eventBtn = (event) => {
    setSelectedCategory(categoria);
  };

  const eventSelect = (event) => {
    setCategoria(event.target.value);
  };

  return (
    <div>
      <div>
        <SearchBlog
          eventBtn={eventBtn}
          eventSelect={eventSelect}
          categoria={categoria}
        />
      </div>
      <div css={sectionCss}>
        {posts.length > 0 &&
          posts.map((post, i) => (
            <div key={i}>
              <PostEntryBlog link={`/blog/${post?.link}`} data={post} />
            </div>
          ))}
      </div>

      <div css={section2Css}>
        {posts && posts.length > 0 && (
          <PaginationBlog
            totalPages={totalPages}
            pageNumber={currentPage}
            eventClick={linkPagination}
          />
        )}
      </div>
    </div>
  );
}
